var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Add New Company" } },
    [
      _c("vx-card", [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center mb-base" },
          [
            _c("vs-avatar", {
              staticClass: "mr-4 mb-4 company-logo-avatar",
              attrs: { src: _vm.logoUrl, icon: "cloud_upload", size: "70px" },
              on: {
                click: function ($event) {
                  return _vm.$refs.companyLogoInput.click()
                },
              },
            }),
            _c(
              "div",
              [
                _c("input", {
                  ref: "companyLogoInput",
                  attrs: { type: "file", hidden: "", id: "companyLogoInput" },
                  on: { change: _vm.handleFileUpload },
                }),
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.companyLogoInput.click()
                      },
                    },
                  },
                  [_vm._v("Upload logo")]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: { type: "border", color: "danger" },
                    on: { click: _vm.removeLogo },
                  },
                  [_vm._v("Remove")]
                ),
                _c("p", { staticClass: "text-sm mt-2" }, [
                  _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Company Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Company Name" },
                model: {
                  value: _vm.form.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyName", $$v)
                  },
                  expression: "form.companyName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Company Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Company Type")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                attrs: {
                  value: _vm.selectedCompanyType,
                  placeholder: "CompanyType",
                  options: _vm.companyTypeOptions,
                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                },
                on: { input: _vm.setSelectedCompanyType },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Address 1")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Address 1" },
                model: {
                  value: _vm.form.address1,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address1", $$v)
                  },
                  expression: "form.address1",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Address 1"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Address 2")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Address 2" },
                model: {
                  value: _vm.form.address2,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address2", $$v)
                  },
                  expression: "form.address2",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Address 2"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("City")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:50",
                    expression: "'max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "City" },
                model: {
                  value: _vm.form.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "city", $$v)
                  },
                  expression: "form.city",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("City"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("County / State")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:50",
                    expression: "'max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "State" },
                model: {
                  value: _vm.form.state,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "state", $$v)
                  },
                  expression: "form.state",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("State"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Country")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                staticClass: "style-chooser",
                attrs: {
                  value: _vm.selectedCountry,
                  placeholder: "Country",
                  options: _vm.countryOptions,
                },
                on: { input: _vm.setSelectedCountry },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Require Users to 2FA")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.form.requireUsersTo2FA,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "requireUsersTo2FA", $$v)
                  },
                  expression: "form.requireUsersTo2FA",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "lg:float-left mt-4" }, [
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("*Required Field"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mr-2",
                attrs: { color: "danger" },
                on: { click: _vm.onCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c("vs-button", { on: { click: _vm.save } }, [_vm._v("Save")]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }